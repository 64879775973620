import { TextField, useTheme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useHistory } from 'react-router';
import { api_product_find } from './api';
import { Product } from './ProductListItem';


export default function ProductSearchBox(props: {token: string, inputValue?: string, onInputValueChange?: (newInputValue: string) => void, onProductIdChange?: (productId: number | null) => void}) {
    const [options, setOptions] = React.useState<Product[]>([]);
    
    const processLoadedOptions = (options: Array<Product>, searchString: string) => {
        if(props.onProductIdChange) {
            let match: Product | undefined = options.find(p => p.name.toLowerCase() === searchString.toLowerCase());
            if(match !== undefined) {
                props.onProductIdChange(match.id);
            }
            else {
                props.onProductIdChange(null);
            }
        }
        setOptions(options);
    };
    const loadOptions = (searchString: string) => {
        if(searchString.length >= 2) {
            api_product_find(props.token, searchString)
                .then(data => processLoadedOptions(data, searchString))
                .catch(console.log);
        }
    };

    const theme = useTheme();

    return (
        <Autocomplete
            fullWidth
            style={{backgroundColor: theme.palette.background.default, borderRadius: theme.shape.borderRadius}}
            getOptionLabel={(option: Product | string) => (typeof option === 'string' ? option : option.name)}
            getOptionSelected={(option: Product, value: Product) => option.id===value.id}
            options={options}
            autoComplete
            freeSolo
            includeInputInList
            inputValue={props?.inputValue}
            filterSelectedOptions
            onInputChange={(_event: React.ChangeEvent<{}>, newInputValue : string) => {
                loadOptions(newInputValue);
                if(props.onInputValueChange) {
                    props.onInputValueChange(newInputValue);
                }
            }}
            renderInput={(params) => (
                <TextField variant="outlined" {...params} />
            )}
            noOptionsText=""
        />
    );
}
