import { IconButton, Toolbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { fade, useTheme } from '@material-ui/core/styles';
import AddProductIcon from '@material-ui/icons/Category';
import AddIcon from '@material-ui/icons/PlaylistAdd';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import ProductSearchBox from './ProductSearchBox';
import QuantitySelector from './QuantitySelector';


export default function ItemPageHeader(props: {token: string, addItemCallback: (productId: number, quantity: number) => void}) {
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
    const [itemQuantity, setItemQuantity] = useState<number>(1);
    const history = useHistory();

    const addItem = () => {
        if(selectedProductId !== null) {
            props.addItemCallback(selectedProductId, itemQuantity);
            // reset form
            setItemQuantity(1);
            setSearchInputValue('');
        }
    };
  
    const theme = useTheme();
    const buttonStyle = (disabled: boolean) => ({color: fade(theme.palette.primary.contrastText, disabled ? 0.4 : 1.0)});

    return (
        <Toolbar style={{backgroundColor: theme.palette.primary.dark}}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={4}>
                    <ProductSearchBox
                        token={props.token}
                        inputValue={searchInputValue}
                        onInputValueChange={setSearchInputValue}
                        onProductIdChange={setSelectedProductId}
                        />
                </Grid>
                
                {searchInputValue && selectedProductId === null &&
                    <Grid item>
                        <IconButton onClick={() => history.push(`/l/${props.token}/products`, {inputValue: searchInputValue})} style={buttonStyle(false)}>
                            <AddProductIcon />
                        </IconButton>
                    </Grid>
                }
                
                {searchInputValue && selectedProductId !== null &&
                    [
                        <Grid item key="quantity-selector">
                                <QuantitySelector
                                    quantity={itemQuantity}
                                    onQuantityChanged={setItemQuantity}
                                    minQuantity={1}
                                    color={theme.palette.primary.contrastText}
                                    />
                        </Grid>,
                        <Grid item key="add-button">
                            <IconButton onClick={addItem} style={buttonStyle(false)}>
                                <AddIcon />
                            </IconButton>
                        </Grid>
                    ]
                }
            </Grid>
        </Toolbar>
    );
}