import { useTheme } from '@material-ui/core/styles';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import ProductEditDialog from './ProductEditDialog';
import ProductListItem, { Product, ProductListItemMode } from './ProductListItem';
import { api_product_list, api_product_reorder, api_product_update } from './api';
import { LIST_ITEM_HEIGHT } from './constants';


export default function ProductList(props: { token: string, highlightedProductId: number | null }) {
    const [products, setProducts] = useState<Array<Product>>([]);
    const highlightedProductRef = useRef<HTMLLIElement>(null);
    const [currentlyEditedProduct, setCurrentlyEditedProduct] = useState<Product | null>(null);
    const [currentlyMovingProduct, setCurrentlyMovingProduct] = useState<Product | null>(null);

    const loadProductList = useCallback(() => {
        return api_product_list(props.token)
            .then(data => setProducts(data))
            .catch(console.log);
    }, []);

    if (products.length === 0) { // not optimal, as product list may actually be empty
        loadProductList();
    }

    const moveProduct = (preceedingProduct: Product | null) => {        
        if(currentlyMovingProduct!==null) {
            api_product_reorder(props.token, currentlyMovingProduct.id, preceedingProduct?.id)
                .then(loadProductList)
                .catch(console.log);
            setCurrentlyMovingProduct(null);
        }
    };

    const modifyProduct = async (modifiedProduct: Product) => {
        await api_product_update(props.token, modifiedProduct.id, {
            name: modifiedProduct.name
        });
        await loadProductList();
    };

    const theme = useTheme();
    useEffect(() => {
        if (props.highlightedProductId !== null) {
            loadProductList().then(() => {
                if (highlightedProductRef.current) {
                    highlightedProductRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
                    const oldBgColor = highlightedProductRef.current.style.backgroundColor;
                    highlightedProductRef.current.style.backgroundColor = theme.palette.primary.light;
                    const timer = setTimeout(() => {
                        if (highlightedProductRef.current) {
                            highlightedProductRef.current.style.backgroundColor = oldBgColor;
                        }
                    }, 2000);
                    return () => clearTimeout(timer);
                }
            });
        }
    }, [props.highlightedProductId, loadProductList, theme.palette.primary.light]);

    
    const Row: React.FC<ListChildComponentProps> = ({ index, style }) => {
        let product : Product | null = null;
        if( currentlyMovingProduct ) {
            if( index >= 1 ) {
                product = products[index-1];
            }
        }
        else
        {
            product = products[index];
        }
        let mode = currentlyMovingProduct === null ? ProductListItemMode.Normal :
            (currentlyMovingProduct === product ? ProductListItemMode.MovingThis :
                ProductListItemMode.MovingOther);
        return (
            <ProductListItem
                product={product}
                mode={mode}
                style={style}
                onMoveClicked = {() => setCurrentlyMovingProduct(product===currentlyMovingProduct ? null : product)}
                onEditClicked = {() => setCurrentlyEditedProduct(product)}
                onDropProductAfterThis= {moveProduct}
            />
        );
    };
      
    return (
        <>
            <AutoSizer>
                {({ height, width } : Size) => {
                    console.log(height);
                    return (
                    <FixedSizeList
                        itemCount={products.length + (currentlyMovingProduct ? 1 : 0)}
                        itemSize={LIST_ITEM_HEIGHT}
                        height={height}
                        width={width}
                        overscanCount={1}
                    >
                        {Row}
                    </FixedSizeList>
                );}}
            </AutoSizer>
            <ProductEditDialog
                open={currentlyEditedProduct !== null}
                product={currentlyEditedProduct}
                onClose={() => setCurrentlyEditedProduct(null)}
                onSubmit={(updatedProduct: Product) => {
                    setCurrentlyEditedProduct(null);
                    modifyProduct(updatedProduct);
                }}
            />
        </>
    );
}
