import { IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import SubmitIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useState } from "react";
import { Product } from './ProductListItem';

export default function ItemEditDialog(props: {
    open: boolean, product: Product | null,
    onClose: () => void,
    onSubmit: (updatedProduct: Product) => void
}) {
    const [product, setProduct] = useState<Product>();

    useEffect(() => {
        if(props.product) {
            setProduct(props.product);
        }
    }, [props.product]);
  
    if(product === undefined) {
        return null;
    }
    
    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {product.name}
            </DialogTitle>
            <DialogContent>

                <TextField key="product-name-field"
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={product.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProduct({...product, name: e.target.value})}
                    />

          </DialogContent>

          <DialogActions>
                <IconButton onClick={props.onClose} color="primary">
                    <CancelIcon />
                </IconButton>
                <IconButton onClick={() => product !== null && props.onSubmit(product)} color="primary">
                    <SubmitIcon />
                </IconButton>
          </DialogActions>
        </Dialog>
    );
  }