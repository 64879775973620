import { Button, Grid, IconButton, InputAdornment, OutlinedInput, Paper, Snackbar, TextField, Typography, useTheme } from "@material-ui/core";
import CopyClipboardIcon from '@material-ui/icons/Assignment';
import OpenLinkIcon from '@material-ui/icons/Launch';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { api_list_create } from "./api";

function ListCreationInput(props: {createList: (listName: string) => void}) {
    const [listName, setListName] = useState<string>("");
    return (
        <Grid container spacing={3} justify="center" direction="column" alignItems="center">
            <Grid item>
                <Typography variant="h4">Create a new list</Typography>
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    label="List Name"
                    variant="outlined"
                    value={listName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setListName(e.target.value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && props.createList(listName)}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={()=>props.createList(listName)}>
                    Create
                </Button>
            </Grid>
        </Grid>
    );
}

function ListUrlDisplay(props: {listUrl: string}) {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const history = useHistory();
    return (
        <Grid container direction="column" spacing={5}>
            <Grid item>
                <Typography>Here is the link to your list:</Typography>
            </Grid>
            <Grid item>
                <OutlinedInput
                    type="text"
                    fullWidth
                    value={props.listUrl}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={() => navigator.clipboard.writeText(props.listUrl).then(() => setShowSnackbar(true))}>
                                <CopyClipboardIcon />
                            </IconButton>
                            <Snackbar
                                open={showSnackbar}
                                autoHideDuration={2500}
                                onClose={() => setShowSnackbar(false)}
                                message="Link copied to clipboard"                                
                            />
                            <IconButton onClick={() => history.push(props.listUrl)}>
                                <OpenLinkIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Grid>
        </Grid>
    );
}

export default function LandingPage(props: {fromInvalidToken?: boolean}) {
    const [listUrl, setListUrl] = useState<string>("");
    const theme = useTheme();

    const createList = async (listName: string) => {
        const listToken = await api_list_create({name: listName});
        const url = new URL(`/l/${listToken}`, document.baseURI);
        setListUrl(url.href);
    }

    return (
        <Grid
            container
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh', backgroundColor: theme.palette.primary.main }}
        >
            <Paper elevation={3} style={{padding: "16px"}}>
                <Grid container direction="column" spacing={5} justify="center" style={{padding: "16px", maxWidth: "95vw"}}>
                    {props.fromInvalidToken && !listUrl ? <Grid item><Typography>This list does not exist.</Typography></Grid> : null}
                    {listUrl ? <ListUrlDisplay listUrl={listUrl}/> : <ListCreationInput createList={createList}/>}
                    <Typography variant="caption" style={{marginTop: "32px"}}>Lists will automatically be deleted after not being used for 90 days. Newly created lists will be deleted after 24h if not visited at least once.</Typography>
                </Grid>
            </Paper>
        </Grid>
    );
}