import { IconButton, InputAdornment, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import SubmitIcon from '@material-ui/icons/CheckCircle';
import CommentIcon from '@material-ui/icons/Comment';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import BuyDateIcon from '@material-ui/icons/Event';
import CancelIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useState } from "react";
import { Item } from './ItemList';
import QuantitySelector from './QuantitySelector';

export default function ItemEditDialog(props: {
    open: boolean, item: Item | null,
    onClose: () => void,
    onSubmit: (updatedItem: Item) => void
    onDelete: () => void
}) {
    const [item, setItem] = useState<Item>();

    useEffect(() => {
        if(props.item) {
            setItem(props.item);
        }
    }, [props.item]);
  
    const theme = useTheme();
    
    if(item === undefined) {
        return null;
    }
    
    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {item.product}
            </DialogTitle>
            <DialogContent>

                <QuantitySelector key="quantity-selector"
                    quantity={item.quantity}
                    onQuantityChanged={(quantity: number) => setItem({...item, quantity: quantity})}
                    minQuantity={1}
                    color={theme.palette.text.primary}
                    />

                <TextField key="comment-field"
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={item.comment}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setItem({...item, comment: e.target.value})}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <CommentIcon />
                        </InputAdornment>
                        ),
                    }}      
                    />

                <TextField key="buy-date-field"
                    autoFocus
                    margin="dense"
                    type="date"
                    fullWidth
                    variant="outlined"
                    value={item.buy_date? item.buy_date : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if(Date.parse(e.target.value)) {
                            setItem({...item, buy_date: e.target.value});
                        }
                        else {
                            setItem({...item, buy_date: null});
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <BuyDateIcon />
                        </InputAdornment>
                        ),
                    }}      
                    />
          </DialogContent>

          <DialogActions>
                <IconButton onClick={props.onDelete} color="primary">
                    <DeleteIcon />
                </IconButton>
                <div style={{flex: '1 0 0'}} />
                <IconButton onClick={props.onClose} color="primary">
                    <CancelIcon />
                </IconButton>
                <IconButton onClick={() => item !== null && props.onSubmit(item)} color="primary">
                    <SubmitIcon />
                </IconButton>
          </DialogActions>
        </Dialog>
    );
  }