import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { api_item_create, api_item_delete, api_item_update, ItemArrayByDate, loadItems } from './api';
import ItemList, { Item } from "./ItemList";
import ItemPageHeader from './ItemPageHeader';
import NavBar, { NavBarOption } from './NavBar';

function ItemPage() {
    const [highlightedItemId, setHighlightedItemId] = useState<number | null>(null);
    const [itemList, setItemList] = useState<ItemArrayByDate>([]);
    const [expandedBuyDates, setExpandedBuyDates] = useState<Array<string | null>>([null, new Date().toISOString().slice(0,10)]);
    const isMounted = useRef<boolean>(true);
    const { token } = useParams<{token: string}>();

    const updateItems = async (expandedBuyDates: Array<string | null>) => {
        const items = await loadItems(token, expandedBuyDates, undefined);
        if(isMounted.current) {
            setItemList(items);
        }
    };
    const createItem = async (productId: number, quantity: number) => {
        const newItem: Item = await api_item_create(token, {productId: productId, quantity: quantity});
        await updateItems(expandedBuyDates);
        setHighlightedItemId(newItem.id);
    };
    const modifyItem = async (modifiedItem: Item) => {
        await api_item_update(token, modifiedItem.id, {
            quantity: modifiedItem.quantity,
            comment: modifiedItem.comment,
            buy_date: modifiedItem.buy_date
        });
        await updateItems(expandedBuyDates);
    };
    const deleteItem = async (itemId: number) => {
        await api_item_delete(token, itemId);
        await updateItems(expandedBuyDates);
    };
    const toggleBuyDate = async (buyDate: string | null) => {
        let updatedExpandedBuyDates: Array<string | null>;
        if(expandedBuyDates.includes(buyDate)) {
            updatedExpandedBuyDates = expandedBuyDates.filter((v: string | null) => v !== buyDate);
        }
        else {
            updatedExpandedBuyDates = [...expandedBuyDates, buyDate];
        }
        await updateItems(updatedExpandedBuyDates);
        setExpandedBuyDates(updatedExpandedBuyDates);
    }

    // initial fetching of data
    useEffect(() => {
        updateItems(expandedBuyDates);
        return () => {isMounted.current = false};
    // eslint-disable-next-line
    }, []);

    return (
        <>
            <NavBar activeOption={NavBarOption.Items}>
                <ItemPageHeader token={token} addItemCallback={createItem}/>
            </NavBar>
            <ItemList
                itemList={itemList}
                highlightedItemId={highlightedItemId}
                onToggleBuyDate={toggleBuyDate}
                modifyItemCallback={modifyItem}
                deleteItemCallback={deleteItem}
            />
        </>
    );
}

export default ItemPage;