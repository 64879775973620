import { Box, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { CSSProperties } from "react";
import MoveIcon from '@material-ui/icons/SwapVert';
import MoveHereIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import { ICON_SIZE, LIST_ITEM_HEIGHT, MAX_LIST_ITEM_WIDTH } from "./constants";

export type Product = {
    id: number,
    name: string
}

export enum ProductListItemMode { Normal, MovingThis, MovingOther };

export default function ProductListItem(props: {
    product: Product | null,
    mode: ProductListItemMode,
    style: CSSProperties,
    onEditClicked: () => void,
    onMoveClicked: () => void,
    onDropProductAfterThis: (product: Product | null) => void
}) {
    return (
        <ListItem
            style={{...props.style, maxWidth: MAX_LIST_ITEM_WIDTH}}
            selected={props.mode === ProductListItemMode.MovingThis}
            disabled={props.mode === ProductListItemMode.MovingOther}
            ContainerProps={{style: props.style}}
            ContainerComponent="div"
        >
            <Box
                key={"move-here" + props.product ? props.product?.id.toString() : "null"}
                style={{display: props.mode === ProductListItemMode.Normal ? "none" : "inline"}}
                position="relative"
                width="0px"
                height="0px"
            >
                <Box position="absolute" zIndex="1" left={-16} top={(LIST_ITEM_HEIGHT-ICON_SIZE)/2}>
                    <IconButton onClick={() => props.onDropProductAfterThis(props.product)}>
                        <MoveHereIcon />
                    </IconButton>
                </Box>
            </Box>
            <ListItemIcon>
                <IconButton onClick={props.onMoveClicked} disabled={props.mode===ProductListItemMode.MovingOther} >
                    <MoveIcon />
                </IconButton>
            </ListItemIcon>
            <ListItemText primary={props.product?.name} />
            <div>
                <ListItemSecondaryAction>
                    <ListItemIcon>
                        <IconButton onClick={props.onEditClicked} disabled={props.mode!==ProductListItemMode.Normal} >
                            <EditIcon />
                        </IconButton>
                    </ListItemIcon>
                </ListItemSecondaryAction>
            </div>
        </ListItem>
    );
}