import { AppBar, Tab, Tabs } from '@material-ui/core';
import ProductsIcon from '@material-ui/icons/Category';
import ItemsIcon from '@material-ui/icons/FormatListBulleted';
import React, { ReactNode } from 'react';
import { useHistory, useParams } from "react-router-dom";

export enum NavBarOption {Products, Items};

const NavBar = (props: {activeOption: NavBarOption, children: ReactNode}) => {
    const history = useHistory();
    const {token} = useParams<{token: string}>();

    const onChange = (event: React.ChangeEvent<{}>, value: NavBarOption) => {
        if(value === NavBarOption.Products && props.activeOption !== NavBarOption.Products) {
            history.push(`/l/${token}/products`);
        }
        else if(value === NavBarOption.Items && props.activeOption !== NavBarOption.Items) {
            history.push(`/l/${token}`);
        }
    };

    return (
        <AppBar position="sticky">
            <Tabs variant="fullWidth" indicatorColor="secondary" value={props.activeOption} onChange={onChange} >
                <Tab icon={<ProductsIcon fontSize="large" />} value={NavBarOption.Products} />
                <Tab icon={<ItemsIcon fontSize="large" />} value={NavBarOption.Items} />
            </Tabs>
            {props.children}
        </AppBar>
    );
}

export default NavBar;