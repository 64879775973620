import { Box, Chip, fade, IconButton } from "@material-ui/core";
import PlusIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';


const QuantitySelector = (props: {quantity: number, onQuantityChanged: (quantity: number) => void, minQuantity: number, color: string}) => {
    const enabledStyle = {color: props.color};
    const fadedColor = fade(props.color, 0.4);
    const disabledStyle = {color: fadedColor};

    const isMinusDisabled = props.quantity <= props.minQuantity;

    return (
        <Box borderRadius={10} borderColor={fadedColor} border={1}>
            <IconButton
                disabled={isMinusDisabled}
                onClick={() => props.onQuantityChanged(Math.max(props.minQuantity, props.quantity - 1))}
                style={isMinusDisabled ? disabledStyle : enabledStyle}
                >
                <MinusIcon />
            </IconButton>
            <Chip label={props.quantity} />
            <IconButton
                onClick={() => props.onQuantityChanged(props.quantity + 1)}
                style={enabledStyle}
                >
                <PlusIcon />
            </IconButton>
        </Box>
    );
}

export default QuantitySelector;