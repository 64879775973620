import { Chip, Collapse, useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import BuyDateIcon from '@material-ui/icons/Event';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import UnbuyIcon from '@material-ui/icons/RemoveShoppingCart';
import BuyIcon from '@material-ui/icons/ShoppingCart';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ItemArrayByDate } from './api';
import ItemEditDialog from './ItemEditDialog';
import { MAX_LIST_ITEM_WIDTH } from './constants';


export type Item = {
    id: number,
    product_id: number,
    product: string,
    quantity: number,
    comment: string,
    buy_date: string | null
}


export default function ItemList(props: {
    itemList: ItemArrayByDate,
    highlightedItemId: number | null,
    onToggleBuyDate: (buyDate: string | null) => void,
    modifyItemCallback: (modifiedItem: Item) => void,
    deleteItemCallback: (itemId: number) => void
}) {
    const highlightedItem = useRef<HTMLLIElement>(null);
    const [currentlyEditedItem, setCurrentlyEditedItem] = useState<Item | null>(null);

    const theme = useTheme();

    useEffect(() => {
        if(props.highlightedItemId !== null && highlightedItem.current) {
            highlightedItem.current.scrollIntoView({block: 'center', behavior: 'smooth'});

            const oldBgColor = highlightedItem.current.style.backgroundColor;
            highlightedItem.current.style.backgroundColor = theme.palette.primary.light;
            const timer = setTimeout(() => {
                if(highlightedItem.current) {
                    highlightedItem.current.style.backgroundColor = oldBgColor;
                }
              }, 2000);
              return () => clearTimeout(timer);
          
        }
    }, [props.highlightedItemId, theme.palette.primary.light]);

    const ItemSublist = (props: {
        subList: Array<Item> | null,
        highlightedItemId: number | null,
        modifyItemCallback: (modifiedItem: Item) => void
    }) => {
        if(props.subList === null) {
            return null;
        }
        return (
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List>
                    {props.subList.map((item: Item) => (
                        <ListItem
                            key={item.id.toString()}
                            role={undefined}
                            dense
                            ref={item.id === props.highlightedItemId ? highlightedItem : undefined}
                            >
                            <ListItemIcon>
                                <IconButton
                                    onClick={() => props.modifyItemCallback({
                                        ...item,
                                        buy_date: item.buy_date ? null : new Date().toISOString().slice(0,10)
                                    })}
                                    edge="end"
                                    aria-label="buy"
                                    >
                                    {item.buy_date? <UnbuyIcon /> : <BuyIcon />}
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText primary={item.product} secondary={item.comment} />
                            {item.quantity > 1 &&
                                <ListItemIcon>
                                    <Chip label={item.quantity} />
                                </ListItemIcon>
                            }
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => setCurrentlyEditedItem(item)} edge="end" aria-label="edit">
                                    <EditIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        );
    }

    return (
        <>
            <List style={{width: '100%', maxWidth: MAX_LIST_ITEM_WIDTH}}>
                {props.itemList.map(([buyDate, subList]) => {
                    return (
                        <Fragment key={buyDate===null? 'null' : buyDate.toString()}>
                            {buyDate!==null &&
                                <ListItem
                                key={buyDate===null? 'null' : buyDate.toString()}
                                role={undefined}
                                button
                                onClick={()=>props.onToggleBuyDate(buyDate)}
                                style={{backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}
                                divider
                                >
                                    <BuyDateIcon />
                                    <ListItemText primary={buyDate} />
                                    {subList !== null ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                            }
                            <ItemSublist subList={subList} highlightedItemId={props.highlightedItemId} modifyItemCallback={props.modifyItemCallback} />
                        </Fragment>
                    );
                })}
            </List>
            <ItemEditDialog
                item={currentlyEditedItem}
                open={currentlyEditedItem !== null}
                onClose={() => setCurrentlyEditedItem(null)}
                onSubmit={(updatedItem: Item) => {
                    setCurrentlyEditedItem(null);
                    props.modifyItemCallback(updatedItem);
                }}
                onDelete={() => {
                    if(currentlyEditedItem !== null) {
                        props.deleteItemCallback(currentlyEditedItem.id);
                        setCurrentlyEditedItem(null);
                    }
                }}
            />
        </>
    );
}