import { Item } from "./ItemList";
import { createBrowserHistory } from 'history';


function api_call(method: string, endpoint: string, data: any = null) {
    let options : RequestInit = {
        method: method,
        headers: {'Content-Type': 'application/json'}
    };

    if(['POST', 'PATCH'].includes(method) && data) {
        options = {...options, body: JSON.stringify(data)};
    }

    return fetch(endpoint, options)
        .then((response) => {
            if([204, 205].includes(response.status)) {
                return null;
            }
            if([404, 401].includes(response.status)) {
                createBrowserHistory().push('/invalid_token');
                window.location.reload();
            }
            if (!response.ok) {
                throw response.text();
            }
            return response.json();
        });
}

export function api_list_create(list: {name: string}) {
    return api_call('POST', `/api/list/create`, {name: list.name});
}
export function api_item_create(token: string, item: {productId: number, quantity: number}) {
    const api_item = {product_id: item.productId, quantity: item.quantity};
    return api_call('POST', `/api/l/${token}/items/create`, api_item);
}
export function api_item_update(token: string, itemId: number, updatedItem: {quantity: number, comment: string, buy_date: string | null}) {
    return api_call('PATCH', `/api/l/${token}/items/update/${itemId}`, updatedItem);
}
export function api_item_delete(token: string, itemId: number) {
    return api_call('DELETE', `/api/l/${token}/items/delete/${itemId}`);
}
export function api_product_list(token: string) {
    return api_call('GET', `/api/l/${token}/products/products`);
}
export function api_product_find(token: string, searchString: string) {
    return api_call('GET', `/api/l/${token}/products/find?q=${searchString}`);
}
export function api_product_create(token: string, product: {name: string}) {
    return api_call('POST', `/api/l/${token}/products/create`, product);
}
export function api_product_reorder(token: string, productId: number, preceedingProductId?: number) {
    const preceedingProductIdString: string = preceedingProductId ? `preceeding_product_id=${preceedingProductId}` : '';
    return api_call('POST', `/api/l/${token}/products/reorder/${productId}?${preceedingProductIdString}`);
}
export function api_product_update(token: string, productId: number, updatedProduct: {name: string}) {
    return api_call('PATCH', `/api/l/${token}/products/update/${productId}`, updatedProduct);
}

export type ItemArrayByDate = Array<[string | null, Array<Item> | null]>;
export async function loadItems(token: string, fullyLoadedBuyDates: Array<string | null> = [null], numBuyDates: number = 10): Promise<ItemArrayByDate> {
    try {
        const buyDates: Array<string | null> = await api_call('GET', `/api/l/${token}/buy_dates?limit=${numBuyDates}`);
        let itemArray: ItemArrayByDate = [];
        for(const buyDate of buyDates) {
            let items: Array<Item> | null = null;
            if(fullyLoadedBuyDates.includes(buyDate)) {
                let request: string = buyDate !== null ? `/api/l/${token}/items/items?buy_date=${buyDate}` : `/api/l/${token}/items/items`;
                items = await api_call('GET', request);
            }
            itemArray.push([buyDate, items]);
        }
        return itemArray;
    }
    catch (error: any){
        console.log(error);
        return [];
    }
}