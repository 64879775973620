import { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import ItemPage from './ItemPage';
import LandingPage from './LandingPage';
import ProductPage from './ProductPage';

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/l/:token/products">
                        <ProductPage />
                    </Route>
                    <Route path={["/l/:token/items", "/l/:token"]} >
                        <ItemPage />
                    </Route>
                    <Route exact path="/invalid_token">
                        <LandingPage fromInvalidToken={true}/>
                    </Route>
                    <Route exact path="/">
                        <LandingPage />
                    </Route>
                    <Route>
                        <Redirect to={{pathname: "/"}} />
                    </Route>
                </Switch>
            </Router>
        );
  }
}

export default App;