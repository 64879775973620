import { fade, IconButton, TextField, Toolbar, useTheme } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/AddToPhotos';
import React, { useState } from 'react';
import { useHistory } from "react-router";
import { api_product_create } from './api';

export default function ItemPageHeader(props: {token: string, inputValue?: string, setHighlightedProductId?: (productId: number) => void}) {
    const [inputValue, setInputValue] = useState<string>(props.inputValue || '');

    const addProduct = () => {
        setInputValue('');
        api_product_create(props.token, {name: inputValue})
            .then(data => props.setHighlightedProductId && props.setHighlightedProductId(data.id))
            .catch(console.log)
    };

    const theme = useTheme();

    return (
        <Toolbar style={{backgroundColor: theme.palette.primary.dark}}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        style={{backgroundColor: theme.palette.background.default, borderRadius: theme.shape.borderRadius}}
                        variant="outlined"
                        value={inputValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && addProduct()}
                        />
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={addProduct} disabled={!inputValue} style={{color: fade(theme.palette.primary.contrastText, inputValue ? 1.0 : 0.4)}}>
                        <AddIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Toolbar>
    );
};