import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import NavBar, { NavBarOption } from './NavBar';
import ProductList from './ProductList';
import ProductPageHeader from './ProductPageHeader';

function ProductPage() {
    const location = useLocation<{inputValue: string}>();
    const [highlightedProductId, setHighlightedProductId] = useState<number | null>(null);
    const { token } = useParams<{token: string}>();

    return (
        <>
            <div style={{display: "flex", height: "100%", flexFlow: "column"}}>
                <div style={{display: "flex", flexGrow: 0, flexShrink: 1}}>
                    <NavBar activeOption={NavBarOption.Products}>
                        <ProductPageHeader token={token} inputValue={location.state?.inputValue} setHighlightedProductId={setHighlightedProductId}/>
                    </NavBar>
                </div>
                <div style={{display: "flex", height: "100%"}}>
                    <ProductList token={token} highlightedProductId={highlightedProductId}/>
                </div>
            </div>
        </>
    );
}

export default ProductPage;